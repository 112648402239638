<template>
  <div>
    <div ref="gantt" />
    <svg height="0" width="0" style="position: absolute">
      <defs>
        <linearGradient
          id="gradBuffer"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
        >
          <stop offset="0%" style="stop-color:rgb(255,0,0);stop-opacity:0.7" />
          <stop offset="45%" style="stop-color:rgb(255,255,0);stop-opacity:0.7" />
          <stop offset="55%" style="stop-color:rgb(255,255,0);stop-opacity:0.7" />
          <stop offset="100%" style="stop-color:rgb(34, 255, 0);stop-opacity:0.7" />
        </linearGradient>

        <pattern
          id="blue"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect
            width="10"
            height="10"
            x="0"
            y="0"
            fill="rgb(71, 114, 255)"
          />
          <path
            d="M 0,10 l 10,-10 M -2.5,2.5 l 5,-5 M 7.5,12.5 l 5,-5"
            stroke-width="2"
            shape-rendering="auto"
            stroke="rgb(21, 76, 255)"
            stroke-linecap="square"
          />
        </pattern>

        <pattern
          id="green"
          patternUnits="userSpaceOnUse"
          width="10"
          height="10"
        >
          <rect
            width="10"
            height="10"
            x="0"
            y="0"
            fill="rgb(50, 210, 80)"
          />
          <path
            d="M 2.5,2.5l5,5M7.5,2.5l5,-5 M2.5,7.5l-5,5M7.5,12.5l5,-5 M-2.5,2.5l5,-5"
            fill="transparent"
            stroke="rgb(10, 173, 34)"
            stroke-width="1"
            stroke-linecap="square"
            shape-rendering="auto"
          />
        </pattern>

        <pattern
          id="brown"
          patternUnits="userSpaceOnUse"
          width="8"
          height="8"
        >
          <rect
            width="8"
            height="8"
            x="0"
            y="0"
            fill="rgb(135, 74, 27)"
          />
          <path
            d="M 2, 0 l 0, 8"
            stroke-width="1"
            shape-rendering="crispEdges"
            stroke="rgb(190, 113, 54)"
            stroke-linecap="square"
          />
          <path
            d="M 0,2 l 8,0"
            stroke-width="1"
            shape-rendering="crispEdges"
            stroke="rgb(190, 113, 54)"
            stroke-linecap="square"
          />
        </pattern>

        <pattern
          id="red"
          patternUnits="userSpaceOnUse"
          width="5"
          height="5"
        >
          <rect
            width="5"
            height="5"
            x="0"
            y="0"
            fill="rgb(249, 162, 64)"
          />
          <circle
            cx="2"
            cy="2"
            r="1"
            fill="rgb(199, 109, 7)"
            stroke="rgb(199, 109, 7)"
            stroke-width="0"
          />
        </pattern>
      </defs>
    </svg>
  </div>
</template>

<script>
import Gantt from 'ccpm-frappe-gantt'
import _omit from 'lodash/omit'
import _debounce from 'lodash/debounce'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import parseISO from 'date-fns/parseISO'

export default {
  name: 'FrappeGantt',
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    showConflict: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    selectKey: {
      type: String,
      default: null,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      internalValue: [],
    }
  },
  computed: {
    tasks () {
      return this.internalValue.map(item => {
        const classes = [`bar-${item.color}`]

        if (this.showConflict && this.hasConflict(item)) {
          classes.push('bar-conflict')
        }

        if (this.selectKey && item[this.selectKey]) {
          classes.push('bar-selected')
        }

        if (this.fixed && !this.selectable) {
          classes.push('bar-static')
        }

        return {
          ..._omit(item, ['color']),
          progress: 0,
          custom_class: classes.join(' '), // eslint-disable-line camelcase
        }
      })
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = value.slice()
      },
    },
    tasks () {
      if (this.$gantt) {
        this.$gantt.refresh(this.tasks)
      }
    },
  },
  mounted () {
    this.$gantt = new Gantt(this.$refs.gantt, this.tasks, {
      /* eslint-disable camelcase */
      view_mode: 'Day',
      header_height: 0,
      bar_height: 30,
      bar_corner_radius: 10,
      arrow_curve: 5,
      padding: 15,
      popup_trigger: '',
      disable_resize: true,
      disable_date_change: this.fixed,
      on_date_change: (task, start, end) => this.onDateChange(task.id, start, end),
      on_click: task => this.onClick(task.id),
      /* eslint-enable */
    })
  },
  methods: {
    hasConflict (task) {
      return this.internalValue
        .filter(item => item.id !== task.id && item.color === task.color)
        .some(item => areIntervalsOverlapping(
          { start: parseISO(task.start), end: parseISO(task.end) },
          { start: parseISO(item.start), end: parseISO(item.end) },
          { inclusive: true },
        ))
    },
    validate () {
      return !this.tasks.some(task => task.custom_class.includes('bar-conflict'))
    },
    onDateChange (taskId, start, end) {
      this.internalValue = this.internalValue
        .map(item => (item.id === taskId ? { ...item, start, end } : item))
      this.emitChanges()
    },
    onClick (taskId) {
      if (this.selectable && this.selectKey) {
        this.internalValue = this.internalValue.map(item => (
          item.id === taskId && item.selectable !== false
            ? { ...item, [this.selectKey]: !item[this.selectKey] }
            : item))
        this.emitChanges()
      }
    },
    // Required for nested changes on dependencies
    emitChanges: _debounce(function emitChanges () {
      this.$emit('input', this.internalValue)
    }, 10),
  },
}
</script>

<style lang="scss">
.gantt .bar-label {
  font-size: 16px !important;
  font-weight: bold !important;
}

.gantt .bar-wrapper:hover .bar {
  opacity: 0.8 !important;
}

.gantt .bar-static:hover {
  cursor: default;
}
.gantt .bar-static:hover .bar {
  opacity: 1 !important;
}

.gantt .bar-red .bar {
  fill: url(#red) !important;
}
.gantt .bar-blue .bar {
  fill: url(#blue) !important;
}
.gantt .bar-green .bar {
  fill: url(#green) !important;
}
.gantt .bar-brown .bar {
  fill: url(#brown) !important;
}
.gantt .bar-buffer .bar {
  fill: url(#gradBuffer) !important;
}
.bar-buffer .bar-label {
  fill: rgb(50, 50, 50) !important;
}

.gantt .bar-conflict .bar {
  stroke: red !important;
  stroke-width: 5 !important;
}
.gantt .bar-selected .bar {
  stroke: #E034A6 !important;
  stroke-width: 5 !important;
}
</style>
